import React from 'react';
import Logger from 'console-log-level';
import Constants from "../../config/Constants";
let log =Logger({ level: Constants.LOGGING_LEVEL });
/*
@TODO log error
 */

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
        errorMsg:''
    };



    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({hasError:true,errorMsg:error });
        log.error('Error boundary caught the following error: ' + errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
           return(
               <div>
           <h1>Sorry, APPSA is currently unavailable.</h1>
                   <p>
                Please contact IT support with the following information: {this.state.errorMsg}
                   </p>
                   </div>
                );

        }
        else{
            return this.props.children;
        }


    }
}
export default ErrorBoundary;