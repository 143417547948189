
import Constants from "../config/Constants";

class AllInstallData{

    id=null;  // unique id for the installer lodging this installation (regoNo=installer below may not be unique)
    address=null;
    phone =null;
    email=null;
    custName=null;
    meterno=null;
    room=null;
    installer=null;
    question=null;
    consent=false;
    reason=[];
    acid=null;
    acbrand=null;
    acmodel=null;
    accc=null;
    achc=null;
    accip=null;
    achip=null;
    acdr1=false;
    acdr2=false;
    acdr4=false;
    acdr5=false;
    acdr6=false;
    acdr7=false;
    photoURL=null;
    installerScriptID=null;
    customerScriptID=null;
    state=Constants.UNVERIFIED_STATUS;

    constructor(customer, installationInfo) {
        this.id = installationInfo.installerid;
        this.address = customer.address ? customer.address.getAddress() : null;
        this.phone=customer.phone? customer.phone: null;
        this.email=customer.email? customer.email:null;
        this.custName=customer.name?customer.name: null;
        this.meterno=customer.meterNum;
        this.room=installationInfo.room?installationInfo.room:null;
        this.installer= installationInfo.installerRegNum;
        this.consent= customer.consentGiven;
        this.reason= customer.reasonForRefusal?customer.reasonForRefusal:null;
        this.customerScriptID=customer.customerScriptID;
        this.acid=installationInfo.id;
        this.acbrand=installationInfo.brand;
        this.acmodel=installationInfo.model;
        this.accc= installationInfo.cc;
        this.achc= installationInfo.hc;
        this.accip=installationInfo.cip;
        this.achip=installationInfo.hip;
        this.acdr1=installationInfo.dr1;
        this.acdr2=installationInfo.dr2;
        this.acdr4= installationInfo.dr4;
        this.acdr5= installationInfo.dr5;
        this.acdr6= installationInfo.dr6;
        this.acdr7=installationInfo.dr7;
        this.photoURL=installationInfo.photoURL?installationInfo.photoURL:null;
        this.state=installationInfo.status;
        this.installerScriptID=installationInfo.installerScriptID;

    }


}
export default AllInstallData;
