import SecureStorage from "../models/SecureStorage";

class Constants {
    //Log levels supported: trace, debug, info, warn, error and fatal.
    static LOGGING_LEVEL='debug';
    static get GEMS_SERVER (){
        return Constants.BASE_DRACR_URL +'aircon';
    }
    static get QUESTION_SERVER (){
       //return 'https://l27gd4u4m0.execute-api.ap-southeast-2.amazonaws.com/dev/question';
        return Constants.BASE_DRACR_URL +'question/0';
    }

    static get BASE_DRACR_URL () {

        if (process.env.REACT_APP_BASE_API) {
            return process.env.REACT_APP_BASE_API
        }
        else {
            return "http://localhost:3000/"
        }
    }

    static get COGNITO_USER_POOL_ID () {

        if (process.env.REACT_APP_COGNITO_USERPOOLID) {
            return process.env.REACT_APP_COGNITO_USERPOOLID
        }
        else {
            // test pool is default
            return "ap-southeast-2_a9ngmJvRY"
        }
    }

    static get COGNITO_WEB_CLIENT_ID () {

        if (process.env.REACT_APP_COGNITO_WEBCLIENTID) {
            return process.env.REACT_APP_COGNITO_WEBCLIENTID
        }
        else {
            // test client is default
            return "88iaoaeotqvj7bki0rl7n71rf"
        }

    }

    static get S3_SERVER(){
        if (process.env.REACT_APP_BASE_API) {
            return process.env.REACT_APP_BASE_API +'photo/sign';
        }
        else {
            //default to test instance for dev
           return 'https://testapi.dracr.com.au/v1/'+'photo/sign';
        }
    }

 //   static INSTALLSCRIPT_URL = process.env.REACT_APP_BASE_API +'refdata?type=landingScript';
    static INSTALLSCRIPT_URL = Constants.BASE_DRACR_URL +'refdata?type=landingScript';

    static CUSTSCRIPT_URL = Constants.BASE_DRACR_URL + 'refdata?type=customerScript';
    static REASON_URL = Constants.BASE_DRACR_URL + 'refdata?type=ncreason';
    static AEMO_URL = Constants.BASE_DRACR_URL +'install';
    static GEMS_URL = Constants.BASE_DRACR_URL +'aircon?state=A';

   // static S3_SERVER = Constants.BASE_DRACR_URL +'photo/sign';

    static AIRCON_NOT_FOUND = 'Plate photo uploaded. Either model or brand not found';

    static VERIFIED_STATUS = 'verified';
    static UNVERIFIED_STATUS = 'unverified';



    static AEMO_SERVER = Constants.BASE_DRACR_URL + 'install';
    static DEFAULT_CUST_SCRIPT = 'As part of every air conditioner installation you\'ll get an email invitation from the Department of the Environment and Energy to register for a program that will help you save energy and access discounts and rebates on your electricity bill';
    static DEFAULT_CUST_SCRIPT_ID = '68dc6a10-b289-11e9-aab2-6b11dd36521d';

    static DEFAULT_INST_SCRIPT = 'As part of your obligations under the XYZ Act, you must collect certain information about every air conditioner you install. That information is recorded by this app.';
    static DEFAULT_INST_SCRIPT_ID = 'cbe2d050-b283-11e9-bdb2-35125d43e501';

    /*
    Google Places Constants
     */
    static GM_STR_NUMBER = 'street_number';
    static GM_SUBURB = 'locality';
    static GM_STATE = 'administrative_area_level_1';
    static GM_STREET= 'route';
    static GM_POSTCODE = 'postal_code';
    static ROOMS_INSTALLED = ['select a room','bedroom', 'lounge room','kitchen','dining room',
        'study','other private space','other common space','multiple rooms'];


    /*
    Authentication constants
     */
    static AUTH={
        mandatorySignIn: true,
        region: "ap-southeast-2",
        userPoolId: Constants.COGNITO_USER_POOL_ID,
        userPoolWebClientId: Constants.COGNITO_WEB_CLIENT_ID,
        // in memory storage of secure stuff like tokens
        storage: SecureStorage
    };

    static SIGNUP={
        header: 'APPSA (Australian Peak Power Saver Assist) Sign Up',
        hideAllDefaults: true,
        defaultCountryCode: '61',
        signUpFields: [
            {
                label: 'Email (will be your user name)',
                key: 'username',
                required: true,
                displayOrder: 1,
                type: 'email'
            },
            {
                label: 'Password',
                key: 'password',
                required: true,
                displayOrder: 2,
                type: 'password'
            },
            {
                label: 'Name',
                key: 'name',
                required: true,
                displayOrder: 3,
                type: 'string'
            },
            {
                label: 'PhoneNumber',
                key: 'phone_number',
                required: false,
                displayOrder: 4,
                type: 'string'
            },
            {
                label: 'ARC Licence Number',
                key: 'regoNum',
                required: true,
                displayOrder: 5,
                type: 'string',
                custom: true
            }
        ],
        usernameAttributes: 'email'
    };





    static reasonsRefusal = ['Worried about security of my a/c','Too busy to have this conversation','Don\'t have enough info to decide'  ];
}
export default Constants;
