
class SecureStorage {

    static store = {};  // create an empry object to store stuff in

    // the promise returned from sync function
    static syncPromise = null;
    // set item with the key
    static setItem(key, value) {
        SecureStorage.store[key] = value;
    }
    // get item with the key
    static getItem(key) {
        return SecureStorage.store[key];
    }
    // remove item with the key
    static removeItem(key) {
        delete SecureStorage.store[key];
    }
    // clear out the storage
    static clear() {

        for (let key in SecureStorage.store) {
            SecureStorage.removeItem(key);
        }

    }

    // If the storage operations are async(i.e AsyncStorage)
    // Then you need to sync those items into the memory in this method
    static sync() {
        if (!SecureStorage.syncPromise) {
            SecureStorage.syncPromise = new Promise((res, rej) => {

                // just succeed, because we aren't using async here
                res();

            });
        }
        return SecureStorage.syncPromise;
    }

    // use this method to grab the token needed to make secure backend calls
    static getToken() {

        for (let key in SecureStorage.store) {

            if (key.endsWith('.idToken')) {
                return SecureStorage.store[key];
            }
        }
    }

    // use this method to grab something from the user data block
    static getUserData(userDataKey) {

        for (let key in SecureStorage.store) {

            if (key.endsWith('.userData')) {

                let userData = JSON.parse(SecureStorage.store[key]);
                let userDataAttributes = userData.UserAttributes;

                for (let i = 0; i < userDataAttributes.length; i++) {

                    if (userDataAttributes[i].Name === userDataKey) {
                        let udObj = userDataAttributes[i];
                        let udValue = udObj.Value;
                        return udValue;
                    }

                }




            }
        }
    }



}
export default SecureStorage;
