/*
@TODO return address as one string
 */
class Address {


    street='';
    suburb='';
    state='';
    //postcode=0;
    postcode='';
    country='Australia';
   // address = this.street + ',' + this.suburb +','+this.state+','+this.postcode;

    getAddress(){
       let fullAddress =  this.street + ',' + this.suburb +','+this.state+','+this.postcode;
       return fullAddress;

    };



}
export default Address;
