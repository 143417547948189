
import React from 'react';
import './Header.css';
import energyLogo from "../../assets/ega_logoshadow.png";
import {Link} from "react-router-dom";
/*
@TOTO Put <nav> in
 */
const Header = ( ) => {

        return (
            <div id="headerContainer" className="headerContainer">
                <img src={energyLogo} alt="energy.gov.au logo"/>
                <div className="headerRight">
                    <Link
                        to={{
                            pathname: '/'
                        }}>
                        <p className="titleText">
                            APPSA
                        </p>
                        <p className="titleTextSmall">
                            Australian Peak Power Saver Assist
                        </p>
                    </Link>

                </div>
            </div>
        );


};
export default Header;

