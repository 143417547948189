import Address from './Address';
class Customer {
    name='';
    email='';
    phone='';
    address = '';
    meterNum = 0;
    questionAsked = '';
    reasonForRefusal = [];
    consentGiven = false;
    //this is the ID of the script the customer gets asked
    customerScriptID='';



    constructor(){
        this.address = new Address();
    }

}
export default Customer;