import React from 'react';
import './App.css';
import Header from '../components/Header/Header';
import ErrorBoundary from "../hoc/ErrorBoundary/ErrorBoundary";
import {BrowserRouter} from "react-router-dom";
import Constants from "../config/Constants";
import Amplify from 'aws-amplify';
//import awsconfig from './aws-exports';
import { Authenticator } from 'aws-amplify-react';
import Routes from "./Routes";

/*
* @TODO Move display of form up to App component
*   export interface SignOutOpts {
    global?: boolean
}
*
 */

Amplify.configure({
    Auth: Constants.AUTH
});

class App extends React.Component {

//includeGreetings={false}
    render() {
        return (
            <BrowserRouter>
                <div className="container">
                    <ErrorBoundary>
                        <Header/>
                        <Authenticator signUpConfig={Constants.SIGNUP}>
                            <Routes/>
                        </Authenticator>
                    </ErrorBoundary>
                </div>
            </BrowserRouter>
        );
    }

}



export default App;
