import React from 'react';
import './SearchBar.css';
import Script from 'react-load-script';
/* global google */
class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.handleScriptLoad = this.handleScriptLoad.bind(this);
    }

    componentDidMount() {
        /*this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
            {"types": ["geocode"]});

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
        this.autocomplete.setFields(['address_components']);*/

    }
    handleScriptLoad(){
        // Declare Options For Autocomplete
        let options = {
            types: ['geocode'],
        };

        // Initialize Google Autocomplete
        /*global google*/ // To disable any eslint 'google not defined' errors
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            options,
        );

        // Fire Event when a suggested name is selected
        this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
        this.autocomplete.setFields(['address_components']);
        this.autocomplete.setComponentRestrictions({'country': ['au']});
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        this.props.onPlaceLoaded(place);
    }



    render() {
        return (
        /* { <input ref={this.autocompleteInput}  id="autocomplete" placeholder="Start entering an address to search on"
           {      type="text" className="autocomplete"></input>}*/
        <div>
            <Script
                url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCFLvfmJSXyqBWYnY0mJe6dpBfUxeKr4sk&libraries=places"
                onLoad={this.handleScriptLoad}
            />
            <input ref={this.autocompleteInput} data-testid="addressSearchBar" id="autocomplete" placeholder="Start entering an address to search on"
                   type="text" className="autocomplete" aria-label="address search bar"></input>

        </div>

        );
    }
}
export default SearchBar;