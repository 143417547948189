import React from 'react';
import './App.css';
import {Route, Switch, Redirect} from 'react-router-dom';
import QuestionForm from '../components/Question/QuestionForm';
import LandingPage from "../components/LandingPage/LandingPage";

class Routes extends React.Component{

    render() {
        if(this.props.authState === "signedIn"){
            return (
                <Switch>
                    <Route path='/QuestionForm' exact component={QuestionForm}/>
                    <Route path="/" component={LandingPage}/>
                </Switch>

            );
        }
        else{
            return(
                <Redirect to='/'/>
            );

        }
    }

}
export default Routes;