import PropTypes from 'prop-types';
import React from 'react';
import './Field.css';

class Field extends React.Component {

  static propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
      controlType:PropTypes.string.isRequired,
      id:PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    arialabel: PropTypes.string,
      className:PropTypes.string,
    validate: PropTypes.func,
    onChange: PropTypes.func.isRequired
  };

  state = {
    value: this.props.value,
    error: false,
    touched: false
  };


  componentDidUpdate(prevProps) {

    if(prevProps.value !== this.props.value) {

       // const name = this.props.name;
        const value = this.props.value;
        let error = false;
        if (this.state.touched) {
            error = this.props.validate ? this.props.validate(value) : false;
        }

        this.setState({value, error});

        // don't propagate it, assume parent has taken care of its state, think if you
        // did propagate could end up in an infinite loop... arggghhh.
        //this.props.onChange({name, value, error});

    }
  }


  onChange = evt => {
    const name = this.props.name;
    const value = evt.target.value;
    let error = false;
    if (this.state.touched) {
      error = this.props.validate ? this.props.validate(value) : false;
    }

    this.setState({value, error});
    this.props.onChange({name, value, error});
  };

  onBlur = evt => {

      const name = this.props.name;
      const value = evt.target.value;
      const error = this.props.validate ? this.props.validate(value) : false;
      // set touched to true, now the blur event has fired on this field
      this.setState({value: value, error: error, touched: true});

      this.props.onChange({name, value, error});
  };


  render() {
    return (
      <div>
        <div>
            {this.props.controlType === "input" ?
                <input
                    className={this.props.className? this.props.className:"form-control"}
                    id={this.props.name}
                    name={this.props.name}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    aria-label={this.props.arialabel}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                />:this.props.controlType === "textarea"?
                    <textarea className={this.props.className? this.props.className:"form-control"}
                              id={this.props.name}
                              name={this.props.name} rows="3"
                              placeholder={this.props.placeholder}
                              value={this.state.value}
                              aria-label={this.props.arialabel}
                              onChange={this.onChange}
                              onBlur={this.onBlur}/>
                    :null
            }
        </div>
        <div className="small">
          {this.state.error}
        </div>
    </div>

  );
  }
};

export default Field;
