import React from 'react';
//import './LandingPage.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Constants from "../../config/Constants";
import ls from "local-storage";
import Button from "react-bootstrap/Button";
import Logger from 'console-log-level';
import Alert from "react-bootstrap/Alert";
let log =Logger({ level: Constants.LOGGING_LEVEL });
class LandingPage extends React.Component {
    state = {
        script:'',
        scriptID:'',
        error:false,
        errorMsg:''

    };


    /*
    @TODO Evan needs to return script and id and only one
    @TODO remove data[0]
     */
    componentDidMount() {
        log.debug('In Landing pg - componentDidMount using new logger');
        log.debug('Constants.INSTALLSCRIPT_URL', Constants.INSTALLSCRIPT_URL);
        axios.get(Constants.INSTALLSCRIPT_URL).then(response=>{
            //  console.log(response);
            let script = '';
            let scriptID = '';
            // this.handleQuestionChange(response.data.question);
            if(!response.data[0] || !response.data[0].value){
                log.warn('No response from landing pg server - setting script to default script');
                script = Constants.DEFAULT_INST_SCRIPT;
                scriptID = Constants.DEFAULT_INST_SCRIPT_ID;

            }
            else{
                script = response.data[0].value;
                scriptID = response.data[0].id;
                log.debug('lp - set script to', script);
                log.debug('lp - set scriptID to', scriptID);

            }

            this.setState({
                script:script,
                scriptID:scriptID
            });

            ls.set('installerScript',script);
            ls.set('installerScriptID',scriptID);


            //save last question asked in ls
        }).catch(error => {
            let errorMsg= 'Error getting script, possibly due to network problems. Using stored script. ' +
                'Please contact IT support with the following details:' +
            error.message;
           /* log.error('In landing page.' + 'error status code: ' + error.response.status);
            if(error.response.status === 501 ||error.response.status === 400 ){
                Alert(errorMsg);
                this.props.history.push('/');
            }*/
            this.revertToStoredQuestion(errorMsg);

        });

    }


    revertToStoredQuestion(errMsg){
        let script = ls.get('installerScript');
        let scriptID = ls.get('installerScriptID');
        if(!script){
            script = Constants.DEFAULT_INST_SCRIPT;
        }
        log.warn('setting script to stored script', script);
        this.setState({script: script, scriptID: scriptID, error: true, errorMsg: errMsg});
    };

    render() {
        return (
            <div>
                <div>
                    {this.state.error?
                        <Alert variant='danger'>
                            {this.state.errorMsg}
                        </Alert> : null}
                </div>
                <div className="col-md-12">
                <h2> Welcome to the Australian Peak Power Saver Assist (APPSA) App </h2>
                </div>

                <div className="col-md-12" id="installerScriptSection">
                    {this.state.script}

                </div>
                <div className="form-group">
                    <Link
                        to={{
                            pathname: '/QuestionForm',
                            search: '?scriptID=' + this.state.scriptID
                        }}>
                        <Button variant="primary"> OK</Button>
                    </Link>

                </div>


            </div>
        )
    }


};


export default LandingPage;

