import Constants from "../config/Constants";

class AirConInstallDetails {
     id=undefined;  // A/C ID from our database
     brand = undefined;
     hc = undefined;
     model= undefined;
     cc = undefined;
     cip = undefined;
     hip=undefined;
     dr1= false;
     dr2 = false;
     dr4 = false;
     dr5 = false;
     dr6 = false;
     dr7= false;
     installerid = undefined; // unique id for this installer
     installerRegNum = undefined; // installer's registration #
     room=undefined;
     photoURL=undefined;
     status = Constants.UNVERIFIED_STATUS;
     //this is the ID of the script the installer gets asked on the welcome page
     installerScriptID=undefined;
}
export default AirConInstallDetails;
