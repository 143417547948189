import React from 'react';
import './QuestionForm.css';
import axios from 'axios';
import DRConsentForm from "../DRConsent/DRConsentForm";
import DRNoConsentForm from "../DRNoConsent/DRNoConsentForm";
//import ErrorBoundary from '../../hoc/ErrorBoundary/ErrorBoundary';
import Constants from "../../config/Constants";
import ls from 'local-storage';
import AirConInstallDetails from '../../models/AirConInstallDetails';
import Customer from "../../models/Customer";
import createFilterOptions from "react-select-fast-filter-options";
import Select from "react-select";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import AllInstallData from "../../models/AllInstallData";
import {Auth} from 'aws-amplify';
import Field from "../Auxillary/Field";
import isInt from 'validator/lib/isInt';
import Validation from "../../config/Validation";
import Logger from "console-log-level";
import SecureStorage from "../../models/SecureStorage";
import Spinner from 'react-bootstrap/Spinner';
let log =Logger({ level: Constants.LOGGING_LEVEL });


class QuestionForm extends React.Component {

    state = {
        error:false,
        errorMsg:'',
        customerScript: '',
        selectedOption: '',
        meterNum: '',
        model:'Select a model...',
        room:'',
        showConsentForm:false,
        showNoConsentForm: false,
        showQuestionForm: false,
        showSuccessfulSubmitForm: false,
        showSubmitAnotherModal: false,
        showNoConsentModal:false,
        showUpload:false,
        refusalReason:'',
        brand:'Select a brand...',
        address: null,
        airConSystem: null,
        modelArr: [],
        brandArr: [],
        modelForBandArr:[],
        installationInfo: null,
        installationInfoRecords:[],
        installerScriptID: '',
        customer: null,
        place:{},
        isDisconnected: false,
        installerRegNum:'',
        modelFilterOptions:[],
        modelSelectOptions:[],
        success:false,
        fileUploadSuccess: false,
        url:'',
        modelOrBrandNotFound:null,
        successfulSubmit:false,
        uploadingPhoto: false,

        // new stuff for form field validation
        fields: {
            meter: ''
        },
        fieldErrors: {},

    };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }



    postDataHandlerAll= (installData)=> {
        log.debug('In postDataHandlerAll');

        // grab the token and shove into the header
        let token = SecureStorage.getToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        };

        axios.post(Constants.AEMO_URL, installData, {headers: headers}).then(
            response => {
                log.debug('Successfully submitted new record to AEMO DB');
                //if another install, show modal, set display consent to true
                log.debug('in pdh post. No error');
                this.setState({error: false, errorMsg: ''});
          }
        ).catch(error => {
           // alert('Error sending data to AEMO. Please try again later and contact IT Support at office@millturn.com.au with these error details: ' + error.message);
            let errMsg = 'Error sending installation data. Will attempt to send data later. Please contact IT Support with these error details: '
                + error.message;
            log.error(errMsg);
            alert(errMsg);
            this.setState({error: true, showNoConsentModal:false, showSubmitAnotherModal:false, errorMsg:errMsg
                    },() => {
                // redirect to landing page
                //this.props.history.push('/');
                this.addInstallRecordToLocalStorage(installData);
            });

        });
    };

    postDataHandler= (installData, hasConsent)=> {
        log.debug('In postDataHandlerConsent');

        // grab the token and shove into the header
        let token = SecureStorage.getToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': token
        };

        axios.post(Constants.AEMO_URL, installData, {headers: headers}).then(
            response => {
                log.debug('Successfully submitted new record to AEMO DB',response);
                //if another install, show modal, set display consent to true
                log.debug('in pdhc post. No error');
                if(hasConsent){
                    this.setState({showQuestionForm:false,
                        showSubmitAnotherModal: true, error: false, errorMsg: ''});
                }
                else{
                    this.setState({showQuestionForm:false,
                        showNoConsentModal: true,error: false, errorMsg: '' });
                }
        }
        ).catch(error => {
          //  alert('Error sending data to AEMO. Please try again later and contact IT Support at office@millturn.com.au with these error details: ' + error.message);
            let errMsg = 'Error sending installation data. Will attempt to send data later. Please contact IT Support with these error details: '
                + error.message;
            log.error(errMsg);
            alert(errMsg);
            this.setState({error: true, showNoConsentModal:false, showSubmitAnotherModal:false, errorMsg:
                    errMsg},() => {
                // redirect to landing page
                //this.props.history.push('/');
                //add to local storage
                this.addInstallRecordToLocalStorage(installData);

               /* setTimeout(() => {
                    this.props.history.push('/');
                }, 4000);*/

            });

        });
    };

    addInstallRecordToLocalStorage(record){
        let installRecArray = ls.get('installRecArray');
        if(installRecArray !== null){
            installRecArray.push(record);
        }
        else{
            installRecArray = [];
            installRecArray.push(record);
        }
        ls.set('installRecArray', installRecArray);
        let tmpinstallarray = ls.get('installRecArray');
        log.debug('Just added record to local storage. Array:',tmpinstallarray );
    }



    clearCustomerInfo(){
        let clearCust = this.state.customer;
        clearCust.name='';
        clearCust.email='';
        clearCust.phone='';
        clearCust.address = '';
        clearCust.consentGiven = false;
       return clearCust;
    };

    handleConnectionChange = () => {
        const condition = navigator.onLine ? 'online' : 'offline';
        log.debug("in hcc - condition = ", condition);
        if (condition === 'online') {
            const webPing = setInterval(
                () => {
                    fetch('https://ipv4.icanhazip.com/', {
                        mode: 'no-cors',
                    })
                        .then(() => {
                            this.setState({ isDisconnected: false}, () => {
                                return clearInterval(webPing)
                            });
                            // console.log("hcc isDisconnected", this.state.isDisconnected);
                        }).catch(() => this.setState({ isDisconnected: true },() => {log.info("hcc isDisconnected" )} ))
                }, 500);
            return;
        }

        this.setState({ isDisconnected: true },() => {log.info("hcc isDisconnected", this.state.isDisconnected)});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
       log.debug('in componentDidUpdate');
        let installRecArray = ls.get('installRecArray');
       // console.log('in componentDidUpdate. installRecArray = ', installRecArray);
        if(!this.state.isDisconnected && !this.state.error && installRecArray !== null ){
            if(installRecArray){
                if(installRecArray.length >0){
                    //console.log('in componentDidUpdate.sending records now online.installRecArray length',installRecArray.length);
                    installRecArray.forEach(record=> {
                            //console.log('sending records now online');
                            this.postDataHandlerAll(record);
                        });
                    installRecArray = null;
                    ls.set('installRecArray', installRecArray);
                    //console.log('setting installRecArray to null');
                };
                if(!this.state.error){
                    ls.remove('installRecArray');
                }
                this.getQuestionsAndGEMSData();
            }

        }

    }

    /*

     */
    componentDidMount() {
        log.debug("in QF componentDidMount");
        let instInfo = new AirConInstallDetails();
        let regNum='';
        let cust = new Customer();

        // set the installer script ID based on what was passed in on the URL query param
        instInfo.installerScriptID = this.getInstallerScriptId();

        //set installer reg num & id from secure store
        instInfo.installerRegNum = SecureStorage.getUserData("custom:regoNum");
        regNum = instInfo.installerRegNum;
        instInfo.installerid = SecureStorage.getUserData("email");
        // FYI - the user.username = cognito unique ID , looks like a UUID. Would be another option.
        this.setState({installerRegNum:regNum});
        this.setState({showQuestionForm:true, installationInfo:instInfo, customer:cust });
        //,
        //    () => {this.parseQueryParams()});
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
        this.handleConnectionChange();
        this.getQuestionsAndGEMSData();

    }


    // get installer script id from params
    getInstallerScriptId () {
        log.debug("In getInstallerScriptId");
        const mySearchParams = new URLSearchParams(this.props.location.search);

        for (const [key, value] of mySearchParams.entries()) {

            if (key === 'scriptID') return value;
            //console.log('search parm ' + key + ' = ' + value);
        }
        // didn't find it - will this get rejected by the backend?
        return null;


    }



    getQuestionsAndGEMSData(){
        if(!this.state.isDisconnected && !this.state.error){

            axios.get(Constants.CUSTSCRIPT_URL).then(response=>{
                //  console.log(response);
                let custScript = '';
                let custScriptID = '';
                // this.handleQuestionChange(response.data.question);
                if(!response.data[0]){
                    custScript = Constants.DEFAULT_CUST_SCRIPT;
                    custScriptID = Constants.DEFAULT_CUST_SCRIPT_ID;
                }
                else {
                    custScript = response.data[0].value;
                    custScriptID = response.data[0].id;
                }

                if (this.state.custScript !== custScript) {
                    let cust = this.state.customer;
                    cust.customerScriptID = custScriptID;
                    this.setState({
                        customer: cust,
                        customerScript: custScript,
                        showConsentForm: false,
                        showNoConsentForm: false
                    });
                    //save last question asked in local storage
                    ls.set('customerScript', custScript);
                    ls.set('customerScriptID',custScriptID );
                }

            }).catch(error => {
                let errorMsg= 'Error getting script data, possibly due to network problems. Using stored script. \' +\n' +
                    '                \'Please contact IT support with the following details:' + error.message;
                log.error(errorMsg + error.message);
                this.revertToStoredQuestion(errorMsg);


            });


            axios.get(Constants.GEMS_URL)
                .then(response => {
                    if(response.data.length > 0){
                        this.setAirConArray(response.data);
                    }
                    else{
                        let errorMsg= 'Error: retrieved GEMS data is empty. Using stored data. Please contact IT support.';
                        log.error(errorMsg);
                        this.revertToStoredGEMSData(errorMsg);
                    }

                })
                .catch(error => {
                    //alert('Error getting or processing GEMS data. Falling back to stored data. Specific error: ' + error.message);
                    let errorMsg= 'Error getting or processing GEMS data, possibly due to network problems. Using stored data.'
                        +'\n' + 'Please contact IT support with the following details:' + error.message;
                    log.error(errorMsg);
                    this.revertToStoredGEMSData(errorMsg);
                });


        }
        else{
           this.revertToStoredQuestion('Network unavailable. Reverting to stored question and GEMS data. Please contact IT support.');
           this.revertToStoredGEMSData('Network unavailable. Reverting to stored question and GEMS data. Please contact IT support. ');
        }
    }

    revertToStoredQuestion(errMsg){
       // alert(errMsg);
        let script = ls.get('customerScript');
        if(!script){
            script = Constants.DEFAULT_CUST_SCRIPT;
        }

        let cust = this.state.customer;
        cust.customerScriptID = ls.get('customerScriptID');
        //console.log('setting script to stored script', script);
        this.setState({customerScript: script, customer: cust, error: true, errorMsg: errMsg});
    };

    revertToStoredGEMSData(errMsg){
       // retrive stored GEMS data. If none available, return to landing page.
        this.setState({error: true, errorMsg: errMsg});
        let storedAirConArr = ls.get('GEMS');
        if(storedAirConArr !== null){
            this.setAirConArray(storedAirConArr);
        }
        else {
            let errMsg = 'Fatal error: unable to proceed as unable to retrieve GEMS Data and no stored GEMS data. Please contact IT Support. ' +
                'Returning you to landing page.';
            this.setState({error:true, errorMsg: errMsg},setTimeout(() => {
                this.props.history.push('/');
            }, 5000) )
        }

    };



    setAirConArray(requestData){
        let airconArr=requestData;
        airconArr.forEach(element=> {
            // console.log(element);
            let brand = element.brand;
            let model = element.model;

            if (!this.state.brandArr.includes(brand)){
                this.state.brandArr.push(brand);
            }
            let found = false;
            //if (!this.state.modelArr.includes(modelNum)){
            this.state.modelArr.forEach(modelElement => {
                if (modelElement.model === model){
                    //set state
                    found=true;
                    return;
                }
            });
            if(!found){
                let acinstall = new AirConInstallDetails();
                acinstall.brand = brand;
                acinstall.installerid = this.state.installationInfo.installerid;
                // already in installationInfo, don't need a separate state item
                acinstall.installerScriptID = this.state.installationInfo.installerScriptID;
                acinstall.installerRegNum = this.state.installationInfo.installerRegNum;
                acinstall.hc = element.hc;
                acinstall.model= element.model;
                acinstall.cc = element.cc;
                acinstall.cip = element.cip;
                acinstall.hip = element.hip;
                acinstall.dr1= element.dr1;
                acinstall.dr2 = element.dr2;
                acinstall.dr4 = element.dr4;
                acinstall.dr5 = element.dr5;
                acinstall.dr6 = element.dr6;
                acinstall.dr7= element.dr7;
                acinstall.id = element.id;
                this.state.modelArr.push(acinstall);

            }

        });
        //map it for display options
        let brandSelectOptions = this.state.brandArr.map(brand => { return {key:brand, value: brand, display: brand} });
        brandSelectOptions.unshift({value: '', display: '(Select a brand)'});
        this.setState({ brandArr: brandSelectOptions });
        ls.set('GEMS',airconArr);

    }

    componentWillUnmount() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
        //clear storage
        /*let installRecArray = ls.get('installRecArray');
        if(installRecArray.length >0){
            ls.clear();
        }*/
    }


    consentHandler = () =>{
        //alert('submit btn pressed');
        if(this.state.selectedOption === 'yes'){
           // this.state.showConsentForm = true;
            const doesShowConsent = this.state.showConsentForm;

            //set up customer and info details here
            let customer = this.state.customer;
            customer.consentGiven = true;
            /*customer.questionAsked = this.state.question;
            customer.meterNum = this.state.meterNum;*/

            let installInfo = this.state.installationInfo;
            if(installInfo.brand === Constants.AIRCON_NOT_FOUND || installInfo.model === Constants.AIRCON_NOT_FOUND ){
                installInfo.status = Constants.UNVERIFIED_STATUS;

            }
            else{
                installInfo.status = Constants.VERIFIED_STATUS;

            }
            this.setState({customer: customer, installationInfo: installInfo,showConsentForm: !doesShowConsent,
                showQuestionForm: false});

        }
        else if (this.state.selectedOption === 'no'){
           // this.state.showNoConsentForm = true;
            //clear out all in customer except meter num, script asked and installer id.
            const doesShowNoConsent = this.state.showNoConsentForm;
            let clearCust = this.state.customer;
            clearCust.name='';
            clearCust.email='';
            clearCust.phone='';
            clearCust.address = '';
            clearCust.consentGiven = false;
            this.setState({showNoConsentForm: !doesShowNoConsent,
                showQuestionForm: false, customer:clearCust});
        }

        this.setState({showQuestionForm: false});

    };


    handleSubmit=(event)=> {
        event.preventDefault();

        // need to take the field values and copy them into the state here (maybe a neater way
        // of doing this, but just getting it working for now...)
        let cust = this.state.customer;
        let num = parseInt(this.state.fields.meter, 10);
        cust.meterNum = num;
        this.setState({
            meterNum: num, customer: cust
        });

        if (this.state.selectedOption === 'yes') {
            // this.state.showConsentForm = true;
            const doesShowConsent = this.state.showConsentForm;
            this.setState({showConsentForm: !doesShowConsent});
        } else if (this.state.selectedOption === 'no') {
            // this.state.showNoConsentForm = true;
            const doesShowNoConsent = this.state.showNoConsentForm;
            this.setState({showNoConsentForm: !doesShowNoConsent});
        }
        this.setState({showQuestionForm: false});

    };

    handleOptionChange = changeEvent => {
        let cust = this.state.customer;
        if (changeEvent.target.value === "yes"){
            cust.consentGiven = true;
        }
        else{
            cust.consentGiven = false;
        }
        this.setState({
            selectedOption: changeEvent.target.value,
            customer: cust
        });
    };

    handleModelNumChange = changeEvent => {

        // ET Added, need to toggle error state, dependent on what they selected.
        const fieldErrors = Object.assign({}, this.state.fieldErrors);
        // toggle error state
        if(changeEvent.value == '' || changeEvent.value == '(Select a model)') {
            fieldErrors['model'] = Validation.MSG_VALERR_MODEL;
        }
        else {
            fieldErrors['model'] = '';
        }
        this.setState({fieldErrors: fieldErrors});


        let selectedModel='';
        (changeEvent.value == Constants.AIRCON_NOT_FOUND)?selectedModel=Constants.AIRCON_NOT_FOUND:selectedModel=changeEvent.value;
        this.setState({
            model: selectedModel
        });
        if(selectedModel !== Constants.AIRCON_NOT_FOUND) {
            this.state.modelArr.forEach(modelElement => {
                if (modelElement.model == changeEvent.value) {
                    //set state
                    this.setState({
                        installationInfo: modelElement
                    });
                    return;

                }
            });
        }

    };


    // this is already associated as "brand" in the modelArr
    //we really need all the details associated with the brand.
    handleBrandChange = changeEvent => {

        log.debug('Brand change called: ', changeEvent.value);


        // ET Added, need to toggle error state, dependent on what they selected.
        const fieldErrors = Object.assign({}, this.state.fieldErrors);
        // toggle error state
        if(changeEvent.value == '') {
            fieldErrors['brand'] = Validation.MSG_VALERR_BRAND;
        }
        else {
            fieldErrors['brand'] = '';
        }
        this.setState({fieldErrors: fieldErrors});

        // orig code continues here -----
        let selectedBrand = '';
        (changeEvent.value == Constants.AIRCON_NOT_FOUND)?selectedBrand=Constants.AIRCON_NOT_FOUND:selectedBrand=changeEvent.value;
        let installInfo = this.state.installationInfo;
        installInfo.brand = selectedBrand;
        this.setState({
            brand: selectedBrand,
            installationInfo:installInfo
        });

        let modelsAssWithBrand = [];
        if(selectedBrand !== Constants.AIRCON_NOT_FOUND){
            this.state.modelArr.forEach(modelElement => {
                if (modelElement.brand === selectedBrand){
                    let found = false;
                    modelsAssWithBrand.forEach(modelAsWithBrandElement => {
                        if (modelElement.model === modelAsWithBrandElement.model){
                            //set state
                            found=true;
                            return;
                        }
                    });

                    if(!found) {
                        modelsAssWithBrand.push(modelElement);
                    }
                }
            });

            modelsAssWithBrand.unshift({key:'(Select a model)', value: '', model:'(Select a model)', display: '(Select a model)', id:0});
            this.setState({modelForBandArr:modelsAssWithBrand });
        }

    };

    handleConsentRefusalReasonChange=(changeEvent)=>{
        this.setState({refusalReason:changeEvent});
        //console.log('refusalReasons = ', this.state.refusalReason);
    };

    /*
      New stuff to handle alternative form validation approach
     */
    onInputChange = ({name, value, error}) => {
        const fields = Object.assign({}, this.state.fields);
        const fieldErrors = Object.assign({}, this.state.fieldErrors);

        fields[name] = value;
        fieldErrors[name] = error;

        this.setState({fields, fieldErrors});
    };

    /*
     Adding a validate method that doesn't rely on the simplevalidator
     */
    validate = () => {
        const fields = this.state.fields;
        const fieldErrors = this.state.fieldErrors;
        const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

        if (!fields.meter) return true;
        // brand and model are mandatory, as is there being a selected option
        if (!this.state.brand) return true;
        if (!this.state.model) return true;
        if (!this.state.selectedOption) return true;
        if (errMessages.length) return true;

        return false;
    };


    handleCancelChildForms=(event)=>{
        event.preventDefault();
        this.clearQuestionForm();
        this.setState({showQuestionForm:true,
            showNoConsentForm:false, showConsentForm:false});

    };

    handleNoConsentSubmit=(event)=>{
        let refusalReason = event;
        //console.log('refusal reason:', refusalReason);
        let cust = this.state.customer;
        cust.address = null;
        cust.consentGiven = false;
       // cust.questionAsked = this.state.question;
        cust.meterNum = this.state.meterNum;
        cust.reasonForRefusal = refusalReason;
        let instData = this.state.installationInfo;
        if(instData.brand === Constants.AIRCON_NOT_FOUND || instData.model === Constants.AIRCON_NOT_FOUND ){
            instData.status = Constants.UNVERIFIED_STATUS;
        }
        else{
            instData.status = Constants.VERIFIED_STATUS;
        }

        let installData = new AllInstallData(cust,instData);
        //  console.log('in handleNoConsentSubmit. installData=',installData);

        if(!this.state.isDisconnected && !this.state.error){
            this.setState({customer:cust,installationInfo: instData,showQuestionForm:true, showConsentForm:false,
                    showNoConsentForm:false},
                () => {this.postDataHandler(installData, false)});
        }
        else{
            let currentStoredInstallArr = ls.get('installRecArray');
            let irArr = currentStoredInstallArr?currentStoredInstallArr:[];
            irArr.push(installData);
            //console.log('in handleConsentSubmit. isDisconnected. pushing install arr to storage:',irArr);
            ls.set('installRecArray',irArr);
            this.setState({error:true,
                errorMsg:'Network unavailable - will send installation record ' +
                    'when network is restored.',
                showQuestionForm:true,
                showConsentForm:false,customer:cust,
                installationInfo: instData,
                showNoConsentForm:false
            });
       }
        this.clearQuestionForm();


    };

    /*

     */
    handleConsentSubmit=(event)=>{
        //console.log('in handleConsentSubmit. ChangeEvent:', event );
        let installData = event;
        //console.log('in handleConsentSubmit. installData:', installData );
        //handle error - direct user to contact IT support - set in post


            //if !isDisconnected, sent off else add to array
            if(!this.state.isDisconnected && !this.state.error){
                log.debug('in handleConsentSubmit. isDisconnected =',this.state.isDisconnected);
                this.setState({
                        showConsentForm:false, showSubmitAnotherModal:false
                    },
                    () => {this.postDataHandler(installData, true)});
            }
            else{
                //save installData
                let currentStoredInstallArr = ls.get('installRecArray');
                let irArr = currentStoredInstallArr?currentStoredInstallArr:[];
                irArr.push(installData);

                ls.set('installRecArray',irArr);
                this.setState({error:true,
                    errorMsg:'Error: network temporarily unavailable - will store installation record and send when network is restored.',
                    showQuestionForm:true,
                    showConsentForm:false,
                    showSubmitAnotherModal:false
                });

            }

        this.clearQuestionForm();
    };

    repConsentFormCancelHandler=(event)=>{
        event.preventDefault();
        // console.log('in repConsentFormCancelHandler');
        this.clearQuestionForm();
        //reset customer info
        let clearCust = this.clearCustomerInfo();
        this.setState({showSubmitAnotherModal:false,showQuestionForm:true, showConsentForm:false, customer: clearCust});
        // redirect to landing page
        this.props.history.push('/');

    };

    repConsentFormContinueHandler=(event)=>{
        if(event){
            event.preventDefault();
        }

        //  console.log('in repConsentFormContinueHandler');
        //show form
        this.setState({showSubmitAnotherModal:false,showQuestionForm:false, showConsentForm:true});

    };

    handleNoConsentOKorClose=(event)=>{
        if(event){
            event.preventDefault();
        }
        this.clearQuestionForm();
        this.setState({showNoConsentModal:false,showQuestionForm:true, showNoConsentForm:false});
        this.props.history.push('/');
    };

    handleFileUploadChange = (ev) => {
        this.setState({success: false, url : "", fileUploadSuccess:true},
            ()=> this.handleFileUpload(ev));

    };

    handleFileUpload= (ev) => {
        let file = this.uploadInput.files[0];
        // Split the filename to get the name and type
        let fileParts = this.uploadInput.files[0].name.split('.');
        let fileName = fileParts[0];
        let fileType = fileParts[1];
        this.setState({uploadingPhoto:true, error: false});
        log.debug("Preparing the upload. photo server URL: ", Constants.S3_SERVER);
        axios.post(Constants.S3_SERVER,{
            fileName : fileName,
            fileType : fileType
        })
            .then(response => {
                //console.log('Response:', response);
                var returnData = response.data.data.returnData;
                var signedRequest = returnData.signedRequest;
                var url = returnData.url;
                let instInfo = this.state.installationInfo;
                instInfo.photoURL = url;
                instInfo.status = Constants.UNVERIFIED_STATUS;
                instInfo.brand = Constants.AIRCON_NOT_FOUND;
                instInfo.model = Constants.AIRCON_NOT_FOUND;
                this.setState({url: url, installationInfo:instInfo});
                //console.log("Received a signed request " + signedRequest);

                // Put the fileType in the headers for the upload
                var options = {
                    headers: {
                        'Content-Type': fileType
                    }
                };
                axios.put(signedRequest,file,options)
                    .then(result => {
                        //console.log("Response from s3");

                        // also now need to clear out any model or brand error messages
                        const fieldErrors = Object.assign({}, this.state.fieldErrors);
                        // toggle error state
                        fieldErrors['brand'] = '';
                        fieldErrors['model'] = '';

                        this.setState({success: true, uploadingPhoto: false, brand:Constants.AIRCON_NOT_FOUND, model:Constants.AIRCON_NOT_FOUND, fieldErrors: fieldErrors});
                    })
                    .catch(error => {
                        //alert("ERROR " + JSON.stringify(error));
                        let errMsg = "Error uploading photo. Please try again and contact IT support if problem persists. Details: "+ error.message;
                        log.error("Error uploading photo. Details: ",JSON.stringify(error) );
                        this.setState({error: true, errorMsg: errMsg, uploadingPhoto: false });
                    })
            })
            .catch(error => {
                //alert(JSON.stringify(error));
                let errMsg = "Error uploading photo. Please try again and contact IT support if problem persists. Details: "+ error.message;
                log.error("Error uploading photo. Details: ",JSON.stringify(error) );
                this.setState({error: true, errorMsg: errMsg, uploadingPhoto: false });
            })
    };

    clearQuestionForm(){
            this.setState({showUpload:false,brand:'',model:'',selectedOption:'',
                success: false, successfulSubmit:false, error: false, errorMsg: ''});
    }

    render() {
        //removed street replaced with address to be set
        let consentForm = null;
        let consentDeniedForm = null;
        let submitAnotherModal = null;
        let noConsentModal = null;

        if(this.state.showSubmitAnotherModal){
            submitAnotherModal=(
            <Modal show={this.state.showSubmitAnotherModal} onHide={this.repConsentFormContinueHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>Successfully submitted! Submit another similar installation?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Continue to copy previous install data into new install? Note: you'll have to set room installed in.</Modal.Body>
                <Modal.Footer>
                   <Button variant="secondary" onClick={this.repConsentFormCancelHandler}>
                        NO
                    </Button>
                    <Button variant="primary" onClick={this.repConsentFormContinueHandler}>
                        YES
                    </Button>
                </Modal.Footer>
            </Modal>);
        }

        if(this.state.showNoConsentModal){
            noConsentModal=(
                <Modal show={this.state.showNoConsentModal} onHide={this.handleNoConsentOKorClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success! </Modal.Title>
                    </Modal.Header>
                    <Modal.Body> Submitted reasons for consent refusal. </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={this.handleNoConsentOKorClose}>
                            Continue
                        </Button>
                    </Modal.Footer>
                </Modal>);
        }

        if(this.state.showConsentForm){
            consentForm = (<div>
                 <DRConsentForm
                     isDisconnected={this.state.isDisconnected}
                    model={this.state.model}
                    installationInfo={this.state.installationInfo}
                    onConsentSubmit={this.handleConsentSubmit}
                    customer={this.state.customer}
                     onCancelConsentSubmit={this.handleCancelChildForms}
                    >
                </DRConsentForm>
            </div>);
        }


        if(this.state.showNoConsentForm){
            consentDeniedForm = ( <div>
               <DRNoConsentForm
                    onConsentRefusalReasonsChange={this.handleConsentRefusalReasonChange}
                    onNoConsentSubmit={this.handleNoConsentSubmit}
                    onCancelNoConsentSubmit={this.handleCancelChildForms}>
               </DRNoConsentForm>
            </div>);
        }

        return <div>
            <div>
            {this.state.error?
                <Alert variant='danger'>
                    {this.state.errorMsg}
                </Alert> : null}
            </div>
            {this.state.showQuestionForm ?
                <form onSubmit={this.handleSubmit}>
                    {/*{this.state.successfulSubmit ?
                        <div className="form-row">
                        <Alert variant='success'>
                            SUCCESSFUL SUBMISSION
                        </Alert>
                        </div>
                        : null}*/}

                    <div className="form-row">
                        <h3>Please read the following script to the customer:</h3>
                            <p id="custScript"> {this.state.customerScript}</p>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6">
                            <label htmlFor="installRegoInput" >Installer's ARC Licence Number</label>
                            <input
                                type="text"
                                aria-label="Installer's Registration Number"
                                id="installRegoInput"
                                name="installRegoInput"
                                className="form-control"
                                value={this.state.installerRegNum}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-4">
                            <label htmlFor="meter">Meter Number</label>

                            <Field
                                name="meter"
                                controlType="input"
                                id="meter"
                                type="number"
                                arialabel="Meter Number"
                                placeholder="enter an 11 digit meter number"
                                value={this.state.fields.meter}
                                onChange={this.onInputChange}
                                validate={val => (isInt(val, {min: 10000000000, max: 99999999999}) ? false : Validation.MSG_VALERR_METER)}

                            />

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="selACBrand" >A/C Brand</label>
                            <Select id="selACBrand" aria-label="airconditioner brand"
                                    onChange={this.handleBrandChange}
                                    options={this.state.brandArr.map((brand) => ({
                                        label: brand.value,
                                        value:brand.value
                                    }))}
                                    value={{label:this.state.brand, value:this.state.brand}}
                                    classNamePrefix="react_select_brand"
                            />
                            <div className="small">
                                {this.state.fieldErrors['brand']}
                            </div>

                        </div>
                        <div className="col-md-4">
                            <label htmlFor="selModelNumInput" >A/C Model number</label>
                            <Select
                                id="selModelNumInput"
                                aria-label="airconditioner model number"
                                name="selModelNumInput"
                                onChange={this.handleModelNumChange}
                                options={this.state.modelForBandArr.map((element) => ({
                                    label: element.model,
                                    value: element.model
                                }))}
                               /* defaultValue={this.getDefaultModelOptionValue}*/
                                filterOptions={createFilterOptions(this.state.modelForBandArr.map((element) => ({
                                    label: element.model,
                                    value: element.model
                                })))}
                                value={{label:this.state.model, value:this.state.model}}
                                classNamePrefix="react_select_model"

                            />
                            <div className="small">
                                {this.state.fieldErrors['model']}
                            </div>
                        </div>

                    </div>
                    <div className="form-row">
                        <div className="col-md-6">
                            <Button variant="link" size="sm"
                                    onClick={() => this.setState({showUpload: true})}>Brand and/or model not in list?
                                Upload nameplate photo.</Button>
                            {this.state.showUpload ?
                                <div>
                                    <input onChange={this.handleFileUploadChange}
                                           aria-label="upload name plate image"
                                           ref={(ref) => {
                                               this.uploadInput = ref;
                                           }} type="file"/>
                                    {this.state.uploadingPhoto?
                                        <Spinner animation="border" variant="primary" />

                                    :null}

                                    <br/>
                                  {/*  <Button variant="primary" disabled={!this.state.fileUploadSuccess} size="sm" onClick={this.handleFileUpload}>UPLOAD</Button>*/}
                                    {this.state.success ?
                                        <Alert variant='success'>
                                            SUCCESSFUL PHOTO UPLOAD
                                        </Alert> : null}
                                </div> : null}
                        </div>
                    </div>
                    <div className="form-row">
                        <h3>Is the AC owner willing to supply their contact details?</h3>
                    </div>
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <label>
                                <input
                                    type="radio"
                                    name="rbAnswerYes"
                                    value="yes"
                                    checked={this.state.selectedOption === "yes"}
                                    onChange={this.handleOptionChange}
                                    className="form-check-input"
                                />
                                Consent granted</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <label>
                                <input
                                    type="radio"
                                    name="rbAnswerNo"
                                    value="no"
                                    className="form-check-input"
                                    onChange={this.handleOptionChange}
                                    checked={this.state.selectedOption === "no"}
                                />
                                Consent refused
                            </label>

                        </div>
{/*                        <div className="small">
                            {this.validator.message('Customer answer', this.state.selectedOption, 'required')}
                        </div>*/}

                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary" disabled={this.validate()} type="submit">SUBMIT
                        </button>
                    </div>

                </form> : null
            }

            {consentForm}
            {consentDeniedForm}
            {submitAnotherModal}
            {noConsentModal}
        </div>;


    }
}
export default QuestionForm;
