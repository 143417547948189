import React from 'react';
import './DRConsentForm.css';
import SearchBar from '../Auxillary/SearchBar';
import Constants from "../../config/Constants";
import AllInstallData from "../../models/AllInstallData";
import Address from "../../models/Address";
import Button from "react-bootstrap/Button";
import Field from "../Auxillary/Field";
// Validation related stuff
import Validation from "../../config/Validation";
import isEmail from 'validator/lib/isEmail';
import isIn from 'validator/lib/isIn';
import isPostalCode from 'validator/lib/isPostalCode';
//import Logger from "console-log-level";
//let log =Logger({ level: Constants.LOGGING_LEVEL });

/* google */

class DRConsentForm extends React.Component {

    state = {
        // new stuff for form field validation
        fields: {
            phone: '',
            email: '',
            street: '',
            suburb: '',
            state: '',
            postcode: '',
            room: '',
            name: '',
        },
        fieldErrors: {},

    };



    componentDidMount() {
        if(this.props.customer.address){
            //console.log('in componentDidMount(). Existing customer info', this.props.customer);
            let cust = this.props.customer;

            const fields = this.state.fields;
            fields.phone = cust.phone
            fields.email = cust.email
            fields.name = cust.name
            fields.street = cust.address.street
            fields.suburb = cust.address.suburb
            fields.state = cust.address.state
            fields.postcode = cust.address.postcode

            this.setState({fields});

        }
    }

    /*
      New stuff to handle alternative form validation approach
     */
    onInputChange = ({name, value, error}) => {
        const fields = Object.assign({}, this.state.fields);
        const fieldErrors = Object.assign({}, this.state.fieldErrors);

        fields[name] = value;
        fieldErrors[name] = error;

        this.setState({fields, fieldErrors});
    };

    /*
     Adding a validate method that doesn't rely on the simplevalidator
     */
    validate = () => {
        const fields = this.state.fields;
        const fieldErrors = this.state.fieldErrors;
        const errMessages = Object.keys(fieldErrors).filter(k => fieldErrors[k]);

        // these fields are required, is not present, then form isn't valid
        if (!fields.phone) return true;
        if (!fields.email) return true;
        if (!fields.street) return true;
        if (!fields.suburb) return true;
        if (!fields.state) return true;
        if (!fields.name) return true;
        if (!fields.room) return true;

        if (errMessages.length) return true;

        return false;
    };



    handleSubmit=(event)=> {
        event.preventDefault();
        //console.log('in handleSubmit for consent: isDisconnected', this.props.isDisconnected);

        let myCustomer = this.props.customer;
        let myInstallDetails = this.props.installationInfo;
        let address = new Address();

        if(myInstallDetails.brand === Constants.AIRCON_NOT_FOUND || myInstallDetails.model === Constants.AIRCON_NOT_FOUND ){
            myInstallDetails.status = Constants.UNVERIFIED_STATUS;
        }
        else {
            myInstallDetails.status = Constants.VERIFIED_STATUS;
        }
        myCustomer.name = this.state.fields.name;
        myCustomer.phone = this.state.fields.phone;
        myCustomer.email = this.state.fields.email;
        address.street = this.state.fields.street;
        address.suburb = this.state.fields.suburb;
        address.postcode = this.state.fields.postcode;
        address.state = this.state.fields.state;
        myInstallDetails.room = this.state.fields.room;
        myCustomer.address = address;
        let allInstall = new AllInstallData(myCustomer,myInstallDetails);
        //console.log('in handleSubmit - valid.allInstall', allInstall);
        this.props.onConsentSubmit(allInstall);

    };

    handlePlaceChange=(changeEvent)=>{
        //console.log("In DRCOnsent - onPlaceChanged - event=");
        let addresses = [...changeEvent.address_components];
        let streetNameTmp = '';
        let streetNumberTmp = 0;
        let postcodeTmp = 0;
        let suburbTmp = '';
        let stateTmp = '';

        addresses.forEach(element => {
            let addressElementType = element.types[0];

            //console.log('types = ', addressElementType);

            switch(addressElementType){
                case(Constants.GM_STR_NUMBER):{
                    streetNumberTmp = element.long_name;
                    break;
                }
                case(Constants.GM_STREET):{
                    streetNameTmp = element.long_name;
                    break;
                }
                case(Constants.GM_SUBURB):{
                   suburbTmp = element.long_name;
                    break;
                }
                case(Constants.GM_STATE):{
                    stateTmp = element.short_name;
                    break;
                }
                case(Constants.GM_POSTCODE):{
                    postcodeTmp = element.long_name;
                    break;
                }
                default:
                    break;
            }

        });

        const fields = Object.assign({}, this.state.fields);
        // fill out the fields, clear any errors
        fields.street = streetNumberTmp + ' ' + streetNameTmp;
        fields.suburb = suburbTmp;
        fields.state = stateTmp;
        fields.postcode = postcodeTmp;

        const fieldErrors = Object.assign({}, this.state.fieldErrors);
        fieldErrors.street = ''
        fieldErrors.suburb = ''
        fieldErrors.state = ''
        fieldErrors.postcode = ''

        this.setState({fields, fieldErrors});

    };

    handleRoomChange=(changeEvent)=>{

        const fields = Object.assign({}, this.state.fields);
        const fieldErrors = Object.assign({}, this.state.fieldErrors);
        fields['room'] = changeEvent.target.value;

        // toggle error state
        if(changeEvent.target.value === Constants.ROOMS_INSTALLED[0]) {
            fieldErrors['room'] = 'Room is required';
            // this is a special case - treat as nothing selected, so submit is disabled
            fields['room'] = '';
        }
        else {
            fieldErrors['room'] = '';
        }

        this.setState({fields, fieldErrors});

    };


    render(){
        return (
            <div>
            <h2>Consent granted</h2>

                   {/* <form onSubmit={this.props.onConsentSubmit}>*/}
                <form onSubmit={this.handleSubmit} >
                            <h3>Preferred contact information</h3>
                    <div className="form-row">
                        <div className="col-md-4 form-group">
                            <label htmlFor="name">Name</label>

                            <Field
                                name="name"
                                controlType="input"
                                type="text"
                                arialabel="name"
                                placeholder="Customer name e.g. Freda Smith"
                                value={this.state.fields.name}
                                onChange={this.onInputChange}
                                validate={val => (Validation.isName(val) ? false : Validation.MSG_VALERR_NAME)}
                            />
                        </div>

                        <div className="col-md-4 form-group">
                            <label htmlFor="phone">Phone #</label>

                            <Field
                                name="phone"
                                controlType="input"
                                type="text"
                                arialabel="phone number"
                                placeholder="Phone number, either mobile or landline, e.g. 0466666666"
                                value={this.state.fields.phone}
                                onChange={this.onInputChange}
                                validate={val => (Validation.isAussiePhoneNum(val) ? false : Validation.MSG_VALERR_PHONE)}
                            />
                        </div>

                        <div className="col-md-4 form-group">
                            <label htmlFor="email">Email</label>

                            <Field
                                name="email"
                                controlType="input"
                                type="email"
                                arialabel="email address"
                                placeholder="example.email@address.com"
                                value={this.state.fields.email}
                                onChange={this.onInputChange}
                                validate={val => (isEmail(val) ? false : Validation.MSG_VALERR_EMAIL)}
                            />

                        </div>
                    </div>
                    <h3>Home details</h3>
                    <div className="form-row">
                        <div className="col-md-4 form-group">
                            <label htmlFor="room">Room installed in</label>
                            <select className="form-control form-control-sm" id="room"
                                    aria-label="select room installed in" onChange={this.handleRoomChange}>
                                {Constants.ROOMS_INSTALLED.map((room) => <option key={room} value={room}>{room}</option>)}
                            </select>
                            <div className="small">
                                {this.state.fieldErrors['room']}
                            </div>
                        </div>

                        <div className="col-md-4 form-group">
                            <label htmlFor="meterNum">Meter #</label>
                            <input type='text' id="meterNum"
                                   aria-label="meter number"
                                   value={this.props.customer.meterNum} readOnly={true} className="form-control form-control-sm"/>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12 form-group">
                            <h3>Address</h3>
                            {!this.props.isDisconnected?
                            <SearchBar
                                place
                                onPlaceLoaded={this.handlePlaceChange}
                                autocomplete={"on"} aria-label="address search bar"
                            />:null}

                            <Field
                                name="street"
                                controlType="input"
                                type="text"
                                arialabel="street"
                                placeholder="Street number and street, e.g. 23 Black St"
                                value={this.state.fields.street}
                                onChange={this.onInputChange}
                                validate={val => (Validation.isStreet(val) ? false : Validation.MSG_VALERR_STREET)}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-4 form-group">

                            <Field
                                name="suburb"
                                controlType="input"
                                type="text"
                                arialabel="suburb"
                                placeholder="Suburb, e.g. Nightcliff"
                                value={this.state.fields.suburb}
                                onChange={this.onInputChange}
                                validate={val => (val !== '' ? false : Validation.MSG_VALERR_SUBURB)}
                            />
                        </div>

                        <div className="col-md-4 form-group">
                            <Field
                                name="state"
                                controlType="input"
                                type="text"
                                arialabel="state"
                                placeholder="State, e.g. NSW"
                                value={this.state.fields.state}
                                onChange={this.onInputChange}
                                validate={val => (isIn(val, ['NSW','VIC','QLD','SA','WA','ACT','NT','TAS']) ? false : Validation.MSG_VALERR_STATE)}
                            />
                        </div>

                        <div className="col-md-4 form-group">

                            <Field
                                name="postcode"
                                controlType="input"
                                type="number"
                                arialabel="postcode"
                                placeholder="4 digit postcode e.g. 2500"
                                value={this.state.fields.postcode}
                                onChange={this.onInputChange}
                                validate={val => (isPostalCode(val, 'AU') ? false : Validation.MSG_VALERR_POSTCODE)}
                            />

                        </div>
                    </div>
                    <div className="form-row">
                        <Button variant="danger" onClick={this.props.onCancelConsentSubmit}>CANCEL
                        </Button>
                        <Button variant="primary"  type="submit" disabled={this.validate()}>SUBMIT
                        </Button>
                    </div>
                    <div>
                        { this.props.installationInfo.brand !== Constants.AIRCON_NOT_FOUND?
                            <div>
                                <h3>Air conditioning installation details</h3>
                                < div className="form-row">
                                <div className="col-md-4 form-group">
                                <label htmlFor="brand">Brand </label>
                                <input type='text' id="brand" value={this.props.installationInfo.brand == null ? '' : this.props.installationInfo.brand} readOnly={true}
                                className="form-control form-control-sm"/>
                                </div>
                                <div className="col-md-4 form-group">
                                <label htmlFor="modelNum">Model # </label>
                                <input type='text' id="modelNum" value={this.props.installationInfo.model} readOnly={true}
                                className="form-control form-control-sm"/>
                                </div>
                                </div>
                                <div className="form-row">
                                <div className="col-md-3 form-group">
                                <label htmlFor="hc">Heating capacity </label>
                                <input type='text' id="hc" value={this.props.installationInfo.hc} readOnly={true}
                                className="form-control form-control-sm"/>
                                </div>
                                <div className="col-md-3 form-group">
                                <label htmlFor="cc">Cooling capacity </label>
                                <input type='text' id="cc" value={this.props.installationInfo.cc} readOnly={true}
                                className="form-control form-control-sm"/>
                                </div>
                                <div className="col-md-3 form-group">
                                <label htmlFor="cip">Cooling input power </label>
                                <input type='text' id="cip" value={this.props.installationInfo.cip} readOnly={true}
                                className="form-control form-control-sm"/>
                                </div>
                                <div className="col-md-3 form-group">
                                <label htmlFor="hip">Heating input power </label>
                                <input type='text' id="hip" value={this.props.installationInfo.hip} readOnly={true}
                                className="form-control form-control-sm"/>
                                </div>
                                </div>
                                <div className="form-row divSpace">
                                <div className="col-md-2 form-group">
                                <input className="form-check-input" id="dr1" type="checkbox" checked={this.props.installationInfo.dr1}
                                readOnly={true} disabled={true}/>
                                <label htmlFor="dr1" className="form-check-label">DR1 </label>
                                </div>
                                <div className="col-md-2 form-group">
                                <input className="form-check-input" id="dr2" type="checkbox" checked={this.props.installationInfo.dr2}
                                readOnly={true} disabled={true}/>
                                <label htmlFor="dr2" className="form-check-label">DR2 </label>
                                </div>
                                <div className="col-md-2 form-group">
                                <input className="form-check-input" id="dr4" type="checkbox" checked={this.props.installationInfo.dr4}
                                readOnly={true} disabled={true}/>
                                <label htmlFor="dr4" className="form-check-label">DR4 </label>
                                </div>
                                <div className="col-md-2 form-group">
                                <input className="form-check-input" id="dr5" type="checkbox" checked={this.props.installationInfo.dr5}
                                readOnly={true} disabled={true}/>
                                <label htmlFor="dr5" className="form-check-label">DR5 </label>
                                </div>
                                <div className="col-md-2 form-group">
                                <input className="form-check-input" id="dr6" type="checkbox" checked={this.props.installationInfo.dr6}
                                readOnly={true} disabled={true}/>
                                <label htmlFor="dr6" className="form-check-label">DR6 </label>
                                </div>
                                <div className="col-md-2 form-group" id="drs">
                                <input className="form-check-input" id="dr7" type="checkbox" checked={this.props.installationInfo.dr7}
                                readOnly={true} disabled={true}/>
                                <label htmlFor="dr7" className="form-check-label">DR7 </label>
                                </div>
                                </div>
                            </div> :null}
                        </div>
                </form>
            </div>


        );
        }
}

export default DRConsentForm;
